import { PageTypesByAuthorizationStatus } from "@aft/constants/general/authorization";

import { NotAuthorizedLayout } from "components/Layout/NotAuthorized";

/**
 * Page body for the 404.
 */
const Custom404 = () => <h1>404 - Page Not Found</h1>;

Custom404.getLayout = (page) => (
  <NotAuthorizedLayout pageTypeByAuthorizationStatus={PageTypesByAuthorizationStatus.Public}>
    {page}
  </NotAuthorizedLayout>
);

export default Custom404;
